/* eslint-disable no-restricted-globals,no-alert */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useFetch, useProfile } from "../../utils";
// import { useParams } from "react-router-dom";
// import { ResourcePicker } from "@shopify/app-bridge/actions";
import StyleIsolation from "../../components/StyleIsolation";
import LegacyContainer from "../../components/LegacyContainer";
import LegacyMessageContext from "../../components/LegacyMessageContext";
import Sorter from "../components/Sorter";
import CreatePagination from "../components/CreatePagination";

function POSWishlist() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const fetch = useFetch();

  const [
    {
      shop: { shop_origin: shopOrigin },
    },
  ] = useProfile();

  const [items, setItems] = useState(25);
  const [page, setPage] = useState(1);
  const [dir, setDir] = useState(null);
  const [order, setOrder] = useState(null);

  const [pendingAdditions, setPendingAdditions] = useState([]);

  const updateDir = useCallback((newDir) => {
    setDir(newDir);
    setLoaded(false);
  }, []);

  useEffect(() => {
    if (!loaded) {
      const url = new URL(`/reggie/wishlists/${id}`, window.location);
      // if (email) {
      //   url.searchParams.append("email", email);
      // }
      url.searchParams.append("items", items || 50);
      url.searchParams.append("page", page || 1);
      if (dir && order) {
        url.searchParams.append("dir", dir);
        url.searchParams.append("order", order);
      }
      fetch(url, { headers: { Accept: "application/json" } })
        .then((r) => r.json())
        .then((nextData) => {
          setData(nextData);
          setLoaded(true);
        })
        .catch((ex) => {
          console.error("Fetching Dashboard Data", ex);
        });
    }
  }, [dir, fetch, id, items, loaded, order, page]);

  const itemsChange = useCallback(
    (e) => {
      setItems(e.target.value);
      if (Number(items) !== Number(e.target.value)) {
        setPage(1);
      }
      setLoaded(false);
    },
    [items]
  );

  const updatePage = useCallback((pageNumber) => {
    setPage(pageNumber);
    setLoaded(false);
  }, []);

  const formSubmitButton = useRef(null);

  const submit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new URLSearchParams(new FormData(e.target));
      const submitButton = e.nativeEvent.submitter;
      if (submitButton && submitButton.name) {
        formData.append(submitButton.name, submitButton.value || "");
      }
      setData(null);
      fetch(`/reggie/wishlists/${id}`, {
        method: "POST",
        body: formData,
        headers: {
          // Accept: "application/json",
        },
      })
        .then((r) => r.text())
        .then((text) => {
          const errorMatch =
            /displayError\("((?:[^\\"]|\\["bfnrtu])*)"\);/.exec(text);
          const successMatch =
            /displaySuccess\("((?:[^\\"]|\\["bfnrtu])*)"\);/.exec(text);
          const warningMatch =
            /displayWarning\("((?:[^\\"]|\\["bfnrtu])*)"\);/.exec(text);

          if (errorMatch)
            console.error("[GiftReggie] Got a response error", errorMatch[1]);
          if (successMatch)
            console.log("[GiftReggie] Got a success error", successMatch[1]);
          if (warningMatch)
            console.warn("[GiftReggie] Got a warning error", warningMatch[1]);

          setLoaded(false);
          setPendingAdditions([]);
        })
        //  .then((r) => r.json())
        //  .then((nextData) => {
        //    setData(nextData);
        //  })
        .catch((ex) => {
          console.error("Fetching Dashboard Data after Submit", ex);
        });
    },
    [fetch, id]
  );

  const discard = useCallback(() => {
    setLoaded(false);
    setData(null);
  }, []);

  const addRegistryLineNumber = useCallback(async () => {
    if (window.shopify) {
      const selection = await window.shopify.resourcePicker({
        type: "product",
        multiple: true,
      });

      const newLines = selection.flatMap((product) =>
        product.variants.map((variant) => ({
          productId: String(product.id).replace(/^.*?\b(?=\d+$)/, ""),
          variantId: String(variant.id).replace(/^.*?\b(?=\d+$)/, ""),
          title: `${product.title} - ${variant.title}`,
          handle: product.handle,
          sku: variant.sku,
          image: variant.image?.originalSrc ?? product.images[0]?.originalSrc,
          // price: variant.price, // TODO: format this
        }))
      );
      setPendingAdditions([...pendingAdditions, ...newLines]);
    } else {
      setPendingAdditions([
        ...pendingAdditions,
        { title: "Product by ID", handle: "", sku: "", blank: true },
      ]);
    }
  }, [pendingAdditions]);

  // Submit form automatically when new items are added
  useEffect(() => {
    formSubmitButton.current?.click();
  }, [pendingAdditions]);

  const addToCart = useCallback(async (variantId, variantTitle) => {
    try {
      await window.shopify.pos.cart.addLineItem(variantId, 1);
      window.shopify.toast.show(`Added ${variantTitle}`);
    } catch (e) {
      window.shopify.toast.show("Could not add Product", { isError: true });
    }
  }, []);

  return data ? (
    <StyleIsolation>
      <LegacyContainer>
        <div className="row-fluid">
          <div className="span12">
            <h3>
              <Link to="/pos_wishlists">Wishlists</Link> &gt; {data.NAME}
            </h3>

            <hr />
          </div>
        </div>

        <div className="row-fluid">
          <div className="span12">
            Wishlist Link:{" "}
            <a
              target="_blank"
              href={`https://${shopOrigin}${data.PROXY_CLEAN_URL}/wishlist/${data.ID}`}
              rel="noreferrer"
            >
              https://{shopOrigin}
              {data.PROXY_CLEAN_URL}/wishlist/{data.ID}
            </a>
            .
          </div>
        </div>

        <form
          action="?"
          id="registry-update-form"
          method="post"
          onSubmit={submit}
        >
          <div className="row-fluid">
            <div className="span12">
              <table className="table table-striped registry-listing">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>CREATED</th>
                    <th>Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.ID}</td>
                    <td>{data.CREATED}</td>
                    <td>
                      {`${data.FIRST_NAME} ${data.LAST_NAME} (${data.EMAIL})`}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div id="wishlist-details" className="wishlist-details">
            <div className="row-fluid">
              <div className="span6">Internal Notes</div>
              <div className="span6">
                <textarea defaultValue={data.EXTRA} name="extra" rows="3" />
              </div>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span6 row-selector">
              Items per page{" "}
              <select
                id="page-selector"
                style={{ width: "80px" }}
                value={items}
                onChange={itemsChange}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span12">
              <div className="span12">
                <button
                  type="button"
                  onClick={addRegistryLineNumber}
                  className="btn btn-reggie"
                  style={{ marginBottom: "10px" }}
                >
                  Add Product
                </button>
              </div>

              <table className="table table-striped registry-listing">
                <tbody>
                  <tr>
                    <th>IMAGE</th>
                    <th id="heading-title">
                      TITLE
                      <Sorter
                        forOrder="title"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-sku">
                      SKU
                      <Sorter
                        forOrder="sku"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th id="heading-price">
                      PRICE
                      <Sorter
                        forOrder="price"
                        dir={dir}
                        order={order}
                        setDir={updateDir}
                        setOrder={setOrder}
                      />
                    </th>
                    <th>Add to Cart</th>
                    <th>DELETE</th>
                  </tr>
                  {data.ITEMS ? (
                    data.ITEMS.map((innerData) => (
                      <tr key={innerData.ID}>
                        <td>
                          <img
                            src={String(innerData.IMAGE).replace(
                              /(?=\.[^.]*$)/,
                              "_160x80"
                            )}
                            alt={innerData.TITLE}
                          />
                        </td>

                        <td>
                          <input
                            type="hidden"
                            name="variant-ids"
                            value={innerData.ID}
                          />
                          {innerData.TITLE}
                        </td>
                        <td>{innerData.SKU}</td>
                        <td>{innerData.PRICE}</td>
                        <td>
                          <button
                            type="button"
                            onClick={() =>
                              addToCart(innerData.VARIANT_ID, innerData.TITLE)
                            }
                          >
                            Add to Cart
                          </button>
                        </td>
                        <td>
                          <button
                            type="submit"
                            className="delete-variant"
                            name="action"
                            value={`delete-${innerData.ID}`}
                          >
                            &times;
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="11">
                        There are currently no items part of this wishlist.
                      </td>
                    </tr>
                  )}
                  {pendingAdditions.map(
                    ({ productId, variantId, title, image, blank }, index) => (
                      <tr key={`${variantId}-${index * 1}`}>
                        <td>
                          {image && (
                            <img
                              src={String(image).replace(
                                /(?=\.[^.]*$)/,
                                "_160x80"
                              )}
                              alt={title}
                            />
                          )}
                        </td>

                        <td>
                          {!blank && (
                            <>
                              <input
                                type="hidden"
                                name="add-registry-product-id"
                                value={productId}
                              />
                              <input
                                type="hidden"
                                name="add-registry-variant-id"
                                value={variantId}
                              />
                            </>
                          )}
                          {title}
                        </td>
                        <td aria-label="empty" />
                        <td aria-label="empty" />
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div id="registry-pagination" style={{ textAlign: "center" }}>
              <CreatePagination
                maxPages={data.MAX_PAGES}
                page={page}
                setPage={updatePage}
              />
            </div>
          </div>
          <button
            type="button"
            className="discard-changes btn btn-danger"
            onClick={discard}
          >
            Discard Changes
          </button>{" "}
          <button
            ref={formSubmitButton}
            type="submit"
            className="btn btn-success"
            name="action"
            value="update"
          >
            Save Changes
          </button>
        </form>
      </LegacyContainer>
    </StyleIsolation>
  ) : null;
}

export default () => (
  <LegacyMessageContext>
    <POSWishlist />
  </LegacyMessageContext>
);
